import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ProductCard from "../Cards/ProductCard";
import { Routes } from "../../routes";
import { useMediaQuery } from "react-responsive";
import { settings } from "./carouselSetting";

const DispProdWithScroller = ({
  dispensaries,
  categoryId,
  categoryName,
  addToCart,
  setShowToast,
  setSuccess,
  setToastBody,
}) => {
  // Media queries
  const isMd = useMediaQuery({ query: "(max-width: 992px)" });
  const isXs = useMediaQuery({ query: "(max-width: 576px)" });

  // Determine the condition for showing the slider
  const showSlider = (products) =>
    (isXs && products.length > 1) ||
    (isMd && products.length > 2) ||
    (!isMd && products.length > 3);

  const cards = (dispensary) =>
    dispensary?.products?.map((product) => (
      <Col xl={3} lg={4} md={5} sm={6} key={product.id}>
        <ProductCard
          setShowToast={setShowToast}
          setToastBody={setToastBody}
          setSuccess={setSuccess}
          product={product}
          dispensary={dispensary}
          addToCart={addToCart}
        />
      </Col>
    ));
  return (
    <>
      {" "}
      {dispensaries?.map((dispensary) => (
        <Row className="mb-5">
          <div className="text-right" sm={2} xs={4}>
            <Link
              className="black-color fw-bold text-end me-4"
              to={{
                pathname: `${
                  Routes.ProductsByCategory.path
                }/${categoryName.replaceAll(" ", "-")}/${categoryId}${
                  dispensary.name && dispensary.id
                    ? `/${dispensary.name.replaceAll(" ", "-")}/${
                        dispensary.id
                      }`
                    : ""
                }`,
              }}
            >
              {dispensary.products.length > 4 && "See All"}
            </Link>
          </div>
          <h3 className="fs-5 mb-0">{dispensary?.name}</h3>
          {dispensary?.distance ? (
            <p className="mb-1 fs-7">
              {dispensary.distance.toFixed(1)} mi away
            </p>
          ) : (
            ""
          )}
          {showSlider(dispensary.products) ? (
            <Slider {...settings} className="card-slider p-2">
              {cards(dispensary)}
            </Slider>
          ) : (
            <Row>{cards(dispensary)}</Row>
          )}
        </Row>
      ))}
    </>
  );
};
export default DispProdWithScroller;
