import { useDispatch, useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import ServerError from "../Login/ServerError";

import axios from "../../axios";
import { Button, Card, Container, Row } from "react-bootstrap";
import LoadingPlaceHolder from "../../reusable/Helper/LoadingPlaceHolder";
import { useEffect, useState } from "react";
import CustomToast from "../../reusable/CustomToast";
import { loadingSet } from "../../redux/loadingSlice";
import DispensarywiseProducts from "../../reusable/DispensaryWiseProducts";
import esaImage from "../../assets/img/adds-3.webp";
import { Helmet } from "react-helmet-async";
import ProductCategories from "./Home/Sections/ProductCategories";
import CustomPagination from "../../components/Pagination";

const ProductsByCategory = (props) => {
  //customtoast states
  const [showToast, setShowToast] = useState(false);
  const [toastBody, setToastBody] = useState("");
  const [success, setSuccess] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const { id, dis_id, category } = props.match.params;
  const userLocation = useSelector((state) => state.login.location);
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentPage(1);
  }, [dis_id]);
  //Query function
  const fetchProdCateg = async () => {
    const res = await axios("/product/categories");
    return res.data.data.categories;
  };
  const fetchCategoryData = async ({ queryKey }) => {
    const [, userLocation, id, dis_id, page] = queryKey;
    const res = await axios(
      `/all/products?limit=${
        dis_id ? 25 : 5
      }&page=${page}&category_id=${id}&dis_id=${dis_id || ""}&lat=${
        userLocation.lat
      }&long=${userLocation.long}&state=${userLocation.state}`
    );
    return res.data.data.category;
  };
  //query variable and loading
  const {
    data,
    isLoading: dataLoading,
    error: dataError,
  } = useQuery({
    queryKey: ["products-by-category", userLocation, id, dis_id, currentPage],
    queryFn: fetchCategoryData,
  });

  const {
    data: searchByCatg = [],
    isLoading: prodCatLoading,
    // error: catError,
  } = useQuery({
    queryKey: ["productCategories"],
    queryFn: fetchProdCateg,
  });
  useEffect(() => {
    dispatch(loadingSet(prodCatLoading || dataLoading));
  });
  if (dataError) {
    return (
      <ServerError
        errorMessage={dataError?.response?.data?.message || dataError}
      />
    );
  }
  // Metadata content
  const metaTitle = `Order ${category} Products Online | Your Cannabis Marketplace`;
  const metaDescription = `Discover premium ${category} products from dispensaries near you. Browse and order online for fast delivery or pickup.`;
  const ogImage = "/favicon.png";
  return (
    <>
      {/* Meta tags with Helmet */}
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:title" content={metaTitle} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={ogImage} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={window.location.href} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:image" content={ogImage} />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Container fluid className="my-4">
        {category.includes("pet") && !dis_id && (
          <Card
            style={{
              backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${esaImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              height: "24rem",
              flexDirection: "row",
              justifyContent: "flex-end",
              position: "relative",
              marginBottom: "2rem",
            }}
          >
            <Button className="green-btn text-white position-absolute bottom-0 m-4">
              <a
                href="https://myesadoctor.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Get your ESA letter
              </a>
            </Button>
          </Card>
        )}
        {prodCatLoading ? (
          <LoadingPlaceHolder height={360} />
        ) : (
          !dis_id && <ProductCategories productCategories={searchByCatg} />
        )}
        <br />
        <br />
        <Row>
          {data?.category?.length ? (
            <>
              <h1 className="fs-3 mb-3 text-center">
                Order {data?.name} products online
              </h1>
              <Row>
                {data?.category?.map((cat) => (
                  <>
                    {cat.category.dispensaries?.length ? (
                      <>
                        <h2 className="fs-4 text-center">
                          {cat.category.name}
                        </h2>

                        <DispensarywiseProducts
                          dispensaries={cat?.category?.dispensaries}
                          categoryId={cat.category.id}
                          categoryName={cat.category.name}
                          setShowToast={setShowToast}
                          setToastBody={setToastBody}
                          setSuccess={setSuccess}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </>
                ))}
              </Row>
            </>
          ) : data?.dispensaries?.length ? (
            <DispensarywiseProducts
              dispensaries={data?.dispensaries}
              categoryId={data.id}
              categoryName={data.name}
              setCurrentPage={setCurrentPage}
              currentPage={currentPage}
              totalPages={data?.totalPages}
              setShowToast={setShowToast}
              setToastBody={setToastBody}
              setSuccess={setSuccess}
            />
          ) : (
            <p className="text-center fontweigh-500 fs-4 my-5">
              No data available for {category}...
            </p>
          )}
        </Row>
        <CustomToast
          show={showToast}
          toastBody={toastBody}
          setShow={setShowToast}
          success={success}
        />
        {data?.totalPages > 1 ? (
          <div className="px-3 border-0 d-lg-flex align-items-center justify-content-end">
            <CustomPagination
              totalPages={data?.totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        ) : (
          ""
        )}
      </Container>
    </>
  );
};
export default ProductsByCategory;
