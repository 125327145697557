import axios from "axios";
import { useEffect, useState, useRef } from "react";
import {
  Button,
  Col,
  Form,
  FormControl,
  FormGroup,
  FormLabel,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "react-bootstrap";

import CustomToast from "../../reusable/CustomToast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMobileAlt,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import { useDispatch } from "react-redux";
import { loadingSet } from "../../redux/loadingSlice";
import { handleChange } from "../../components/Helpers/HelperFunctions";
import PlaceAutocomplete from "../../reusable/Autocomplete/PlaceAutocomplete";

const MMJESALeadForm = ({ show, handleClose, type }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    mobile: "",
    state: "",
    status: "submitted",
    platform: "website",
    source: "https://weedtome.com/",
    site_id: type === "ESA" ? 2 : "",
  });
  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      site_id: type === "ESA" ? 2 : "",
    }));
  }, [type]);

  // Toast states
  const [toastBody, setToastBody] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [success, setSuccess] = useState(true);
  const [location, setLocation] = useState({ state: "" });

  // Loading state
  const [loading, setLoading] = useState(false);

  // Dispatch
  const dispatch = useDispatch();

  // useEffect to handle loading state with Redux
  useEffect(() => {
    dispatch(loadingSet(loading));
  }, [loading, dispatch]);

  useEffect(() => {
    setFormData((prevData) => ({ ...prevData, state: location.state }));
  }, [location.state]);
  // Function to handle saving the user
  const handleSave = async () => {
    setLoading(true);

    try {
      const res = await axios.post(
        "https://admin.onlinemedicalcard.com/api/v2/leads/save",
        formData
      );
      setShowToast(true);
      setToastBody(res.data.message);
      setSuccess(true);
      handleClose();
      const { first_name, last_name, email, mobile, state, site_id } = formData;
      const redirectUrl = `https://app2.onlinemedicalcard.com/register?site_id=${site_id}&first_name=${encodeURIComponent(
        first_name
      )}&last_name=${encodeURIComponent(last_name)}&email=${encodeURIComponent(
        email
      )}&mobile=${encodeURIComponent(mobile)}&state=${encodeURIComponent(
        state || ""
      )}`;

      // Redirect the user
      window.location.href = redirectUrl;
    } catch (err) {
      setShowToast(true);
      setToastBody(err.response.data.message);
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal show={show} size="md" onHide={handleClose}>
        <ModalHeader closeButton>
          <Modal.Title className="fontweigh-500">Apply for {type}</Modal.Title>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Form className="mt-4">
              <Row>
                <Col>
                  <FormGroup className="mb-4">
                    <FormLabel className="fontweigh-500">First Name</FormLabel>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <FormControl
                        autoFocus
                        required
                        type="text"
                        name="first_name"
                        value={formData.name}
                        onChange={(e) => handleChange(e, setFormData)}
                        placeholder="John"
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup className="mb-4">
                    <FormLabel className="fontweigh-500">Last Name</FormLabel>
                    <InputGroup>
                      <InputGroup.Text>
                        <FontAwesomeIcon icon={faUser} />
                      </InputGroup.Text>
                      <FormControl
                        autoFocus
                        required
                        type="text"
                        name="last_name"
                        value={formData.name}
                        onChange={(e) => handleChange(e, setFormData)}
                        placeholder="Doe"
                      />
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <FormGroup id="email" className="mb-4">
                <FormLabel className="fontweigh-500">Your Email</FormLabel>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faEnvelope} />
                  </InputGroup.Text>
                  <FormControl
                    autoFocus
                    required
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={(e) => handleChange(e, setFormData)}
                    placeholder="example@company.com"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="mb-4">
                <FormLabel className="fontweigh-500">Mobile</FormLabel>
                <InputGroup>
                  <InputGroup.Text>
                    <FontAwesomeIcon icon={faMobileAlt} />
                  </InputGroup.Text>
                  <FormControl
                    autoFocus
                    required
                    type="text"
                    name="mobile"
                    value={formData.mobile}
                    onChange={(e) => handleChange(e, setFormData)}
                    placeholder="8888888888"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="mb-4">
                <FormLabel>
                  State<span className="text-danger">*</span>{" "}
                </FormLabel>
                <PlaceAutocomplete
                  setNewLocation={setLocation}
                  newLocation={location}
                  type="state"
                />
              </FormGroup>
            </Form>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline-danger"
            className="fontweigh-500"
            onClick={handleClose}
          >
            Close
          </Button>
          <Button className="green-btn fontweigh-500" onClick={handleSave}>
            Save Changes
          </Button>
        </ModalFooter>
      </Modal>
      <CustomToast
        show={showToast}
        toastBody={toastBody}
        setShow={setShowToast}
        success={success}
      />
    </>
  );
};

export default MMJESALeadForm;
