import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  Row,
  Table,
} from "react-bootstrap";
import React, { useState, useMemo, useCallback } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../../axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import moment from "moment";
import CustomToast from "../../reusable/CustomToast";
import CustomPagination from "../Pagination";

// Memoized BlogCommentsTable component to avoid unnecessary re-renders
const BlogCommentsTable = React.memo(
  ({ blogComments, totalPages, currentPage, setCurrentPage }) => {
    // States for custom toast notifications
    const [showToast, setShowToast] = useState(false);
    const [toastBody, setToastBody] = useState("");
    const [success, setSuccess] = useState(true);

    // Get user data from Redux
    const { role, plan_id } = useSelector((state) => state.login.userData);

    // Query Client for invalidating and refetching queries
    const queryClient = useQueryClient();

    // Memoize blog comments with formatted date using moment.js
    const memoizedBlogComments = useMemo(() => {
      return blogComments?.map((comment) => ({
        ...comment,
        formattedDate: moment(comment.updated_at).format("DD MMM YYYY"),
      }));
    }, [blogComments]);

    // React Query mutation for updating comment status
    const statusMutation = useMutation({
      mutationFn: async ({ id, currentStatus }) => {
        return axios.post(`/blog/comment/update/${id}`, {
          status: currentStatus === 1 ? 0 : 1,
        });
      },
      onSuccess: (res) => {
        setShowToast(true);
        setToastBody(res.data.message);
        setSuccess(true);
        queryClient.invalidateQueries(["blog-comments-list"]); // Invalidate query to refetch updated data
      },
      onError: (err) => {
        setShowToast(true);
        setToastBody(err.response?.data?.message || "An error occurred");
        setSuccess(false);
      },
    });

    // Callback for status change with useCallback to avoid re-creation
    const statusHandler = useCallback(
      (id, currentStatus) => {
        statusMutation.mutate({ id, currentStatus });
      },
      [statusMutation]
    );

    return (
      <>
        <Card
          border="light"
          className="table-wrapper table-responsive shadow-sm "
        >
          <Card.Body className="p-0 pb-4 justify-content-center">
            {memoizedBlogComments?.length > 0 ? (
              <Table responsive hover className="user-table min-height">
                <thead>
                  <tr>
                    <th className="border-bottom">ID</th>
                    <th className="border-bottom">Comment</th>
                    <th className="border-bottom">Commented By</th>
                    <th className="border-bottom">Status</th>
                    <th className="border-bottom">Commented Date</th>
                    <th className="border-bottom">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {memoizedBlogComments.map((comment) => (
                    <tr key={comment.id}>
                      <td text-label="ID">
                        <span className="fw-normal">{comment.id}</span>
                      </td>
                      <td text-label="Comment">
                        <span className="fw-normal">{comment.comment}</span>
                      </td>
                      <td text-label="Commented By">
                        <span className="fw-normal">{comment.user.name}</span>
                      </td>
                      <td text-label="Status">
                        <span className="fw-normal">
                          {comment.status ? "Yes" : "No"}
                        </span>
                      </td>
                      <td text-label="Commented Date">
                        <span className="fw-normal">
                          {comment.formattedDate}
                        </span>
                      </td>
                      <td>
                        <Dropdown as={ButtonGroup} drop="down-centered">
                          <Dropdown.Toggle
                            as={Button}
                            split
                            variant="link"
                            className="text-dark m-0 p-0"
                            disabled={role === "partner" && plan_id === 1}
                          >
                            <span className="icon icon-sm">
                              <FontAwesomeIcon
                                icon={faEllipsisH}
                                className="icon-dark"
                              />
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                statusHandler(comment.id, comment.status)
                              }
                            >
                              <FontAwesomeIcon
                                icon={faEdit}
                                className="icon-dark"
                              />{" "}
                              {comment.status === 1
                                ? "Mark status inactive"
                                : "Mark status active"}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Row className="justify-content-center align-item-center text-dark fontweigh-500 p-4">
                No Data Available.....
              </Row>
            )}
          </Card.Body>
          {totalPages > 1 && (
            <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-end">
              <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </Card.Footer>
          )}
        </Card>

        <CustomToast
          show={showToast}
          toastBody={toastBody}
          setShow={setShowToast}
          success={success}
        />
      </>
    );
  }
);

export default BlogCommentsTable;
