import axios from "../../axios";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Button,
  Col,
  FormGroup,
  FormLabel,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "react-bootstrap";

import CustomToast from "../../reusable/CustomToast";
import { useDispatch, useSelector } from "react-redux";
import { loadingSet } from "../../redux/loadingSlice";
import Select from "react-select";

const AddEmailInstance = ({ show, handleClose, refetchEmailSentData }) => {
  const [state, setState] = useState(null);
  const [dispensaries, setDispensaries] = useState([]);
  const [selectOption, setSelectOption] = useState(null);
  const [error, setError] = useState("");

  // Toast states
  const [toastBody, setToastBody] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [success, setSuccess] = useState(true);

  // Loading state from Redux
  const loading = useSelector((state) => state.loading.loading);
  const dispatch = useDispatch();

  // Fetch states
  const getStates = useCallback(async () => {
    const res = await axios(`/states`);
    return res.data.data.states;
  }, []);

  const { data: states = [], isLoading: statesLoading } = useQuery({
    queryKey: ["states-list"],
    queryFn: getStates,
  });

  // Fetch dispensaries based on the selected state
  const getDispensaries = useCallback(async ({ queryKey }) => {
    const [, selectedState] = queryKey;
    const res = await axios(`/get/dispensary?state=${selectedState.value}`);
    return res.data.data.dispensaries;
  }, []);

  const { data: stateDispensaries = [], isLoading: stateDispensariesLoading } =
    useQuery({
      queryKey: ["state-dispensaries-list", state],
      queryFn: getDispensaries,
      enabled: !!state,
    });

  // Memoize dispensary options
  const dispensaryOptions = useMemo(
    () =>
      stateDispensaries.map((disp) => ({ label: disp.name, value: disp.id })),
    [stateDispensaries]
  );

  // Memoize state options
  const stateOptions = useMemo(
    () => states.map((state) => ({ label: state.state_name, value: state.id })),
    [states]
  );

  // Handle saving the email instance
  const handleSave = useCallback(async () => {
    if (!state || !dispensaries.length) {
      setError("Please add State and dispensaries");
      return;
    }
    dispatch(loadingSet(true));
    try {
      const res = await axios.post("/send/email/dispensary", {
        ids: dispensaries.map((disp) => disp.value),
      });

      setShowToast(true);
      setToastBody(res.data.message);
      setSuccess(true);
      refetchEmailSentData();
      handleClose();
    } catch (err) {
      setShowToast(true);
      setToastBody(err.response?.data?.message || "An error occurred");
      setSuccess(false);
    } finally {
      dispatch(loadingSet(false));
    }
  }, [state, dispensaries, dispatch, refetchEmailSentData, handleClose]);

  useEffect(() => {
    dispatch(loadingSet(statesLoading || stateDispensariesLoading));
  }, [statesLoading, stateDispensariesLoading, dispatch]);

  const handleCloseAndClearState = useCallback(() => {
    handleClose();
    setState(null);
    setDispensaries([]);
    setSelectOption(null);
    setError("");
  }, [handleClose]);

  useEffect(() => {
    if (selectOption?.value === "all") {
      setDispensaries(dispensaryOptions);
    } else {
      setDispensaries([]);
    }
  }, [selectOption, dispensaryOptions]);

  // Select options for all or specific dispensaries
  const selectOptions = useMemo(
    () => [
      { label: "All", value: "all" },
      { label: "Select by dispensary ids", value: "selected" },
    ],
    []
  );

  return (
    <>
      <Modal show={show} onHide={handleCloseAndClearState}>
        <ModalHeader closeButton>
          <Modal.Title className="fontweigh-500">
            Create Email Instance
          </Modal.Title>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={6} className="mb-3">
              <FormGroup>
                <FormLabel className="fontweigh-500">State</FormLabel>
                <Select
                  name="state"
                  placeholder="Select State"
                  value={state}
                  onChange={setState}
                  options={stateOptions}
                />
                <p className="text-danger">{error}</p>
              </FormGroup>
            </Col>
            <Col md={6} className="mb-3">
              <FormGroup>
                <FormLabel className="fontweigh-500">Select Options</FormLabel>
                <Select
                  name="select options"
                  placeholder="Select Option"
                  value={selectOption}
                  onChange={setSelectOption}
                  options={selectOptions}
                />
                <p className="text-danger">{error}</p>
              </FormGroup>
            </Col>
            {selectOption?.value === "selected" && (
              <Col className="mb-3">
                <FormGroup>
                  <FormLabel className="fontweigh-500">Dispensaries</FormLabel>
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    isClearable
                    name="dispensaries"
                    placeholder="Select Dispensaries"
                    value={dispensaries}
                    onChange={setDispensaries}
                    options={dispensaryOptions}
                  />
                  <p className="text-danger">{error}</p>
                </FormGroup>
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            variant="outline-danger"
            className="fontweigh-500"
            onClick={handleCloseAndClearState}
            disabled={loading}
          >
            Close
          </Button>
          <Button
            className="green-btn fontweigh-500"
            onClick={handleSave}
            disabled={loading}
          >
            {loading ? "Sending..." : "Send Email"}
          </Button>
        </ModalFooter>
      </Modal>
      <CustomToast
        show={showToast}
        toastBody={toastBody}
        setShow={setShowToast}
        success={success}
      />
    </>
  );
};

export default AddEmailInstance;
