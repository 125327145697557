import React, { useCallback, useState, useMemo, useEffect } from "react";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";
import axios from "../../axios";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import debounce from "lodash.debounce";
import {
  faCannabis,
  faSearch,
  faStore,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AsyncSearchBar = ({ lat, long, state, setExpanded }) => {
  const [query, setQuery] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [defaultOptions, setDefaultOptions] = useState([]);
  const history = useHistory();
  const animatedComponents = makeAnimated();

  // Function to fetch options
  const fetchOptions = useCallback(
    async (inputValue = "") => {
      try {
        const { data } = await axios.get(
          `/search/dis/with/product?lat=${lat}&long=${long}&state=${state}&name=${inputValue}`
        );

        return (
          data.data?.map((item) => ({
            value: item.id,
            label: item.name,
            type: item.type,
            icon: item.type.includes("dispensary") ? faStore : faCannabis,
            disp_id: item?.dis_id,
          })) || []
        );
      } catch (error) {
        console.error("Error fetching options:", error);
        return [];
      }
    },
    [lat, long, state]
  );

  // Fetch default options on mount
  useEffect(() => {
    const fetchInitialData = async () => {
      const options = await fetchOptions();
      setDefaultOptions(options);
    };
    fetchInitialData();
  }, [fetchOptions]);

  // Debounce the API call to reduce unnecessary requests
  const debouncedFetchOptions = useMemo(
    () =>
      debounce((inputValue, callback) => {
        fetchOptions(inputValue).then(callback);
      }, 500),
    [fetchOptions]
  );

  // Custom styles for AsyncSelect
  const customStyles = useMemo(
    () => ({
      control: (provided, state) => ({
        ...provided,
        padding: state.isFocused ? "5px" : "",
        borderColor: state.isFocused ? "#65ac4b" : "#9fa1a2",
        boxShadow: state.isFocused ? "0 0 0 1px #65ac4b" : "0 0 0 1px #9fa1a2",

        "&:hover": {
          borderColor: "#65ac4b",
          boxShadow: "0 0 0 1px #65ac4b",
        },
      }),
    }),
    []
  );

  // Handle select change
  const handleSelectChange = useCallback(
    (selectedOption) => {
      if (!selectedOption) return;
      const { label, value, type, disp_id } = selectedOption;
      const name = label.split("(")[0];

      const path = type.includes("dispensary")
        ? `${Routes.dispensaryDetails.path}/${value}/${name}/profile`
        : `${Routes.productDetails.path}/${name.replaceAll(
            " ",
            "-"
          )}/${value}/${disp_id}`;
      setExpanded(false);
      history.push({ pathname: path });

      setSelectedOption(null);
      setQuery("");
    },
    [history, setExpanded]
  );

  // Format the option label
  const formatOptionLabel = useCallback(
    ({ label, icon }) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <FontAwesomeIcon icon={icon} style={{ marginRight: 10 }} />
        {label}
      </div>
    ),
    []
  );

  return (
    <div className="position-relative mt-1">
      <label
        style={{ top: "-10px" }}
        className="position-absolute left-3 z-1 bg-white fontweigh-500 px-1"
      >
        Products & Dispensaries
      </label>
      <AsyncSelect
        cacheOptions
        defaultOptions={defaultOptions}
        isClearable
        components={animatedComponents}
        loadOptions={debouncedFetchOptions}
        styles={customStyles}
        inputValue={query}
        value={selectedOption}
        onInputChange={(value) => setQuery(value)}
        onChange={handleSelectChange}
        placeholder={<FontAwesomeIcon icon={faSearch} className="z-1" />}
        formatOptionLabel={formatOptionLabel}
      />
    </div>
  );
};

export default AsyncSearchBar;
