import { Card, Row, Col, Image } from "react-bootstrap";
import { Routes } from "../../../../routes";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const ProductCategories = ({ productCategories }) => {
  return (
    <Row>
      {productCategories.map((cat, index) => (
        <Col key={index} xs={6} sm={4} md={3} lg={2} className="mb-3 px-1">
          <Card
            className="d-flex align-items-center"
            as={Link}
            to={{
              pathname: `${
                Routes.ProductsByCategory.path
              }/${cat.name?.replaceAll(" ", "-")}/${cat.id}`,
            }}
          >
            <Image
              src={cat.cover_image}
              width={80}
              height={60}
              className="mt-1 rounded"
            />
            <span className="fs-6 green-color">{cat.name}</span>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

export default ProductCategories;
