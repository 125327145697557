// AllNotifications.js
import React from "react";
import {
  ListGroup,
  Tooltip,
  OverlayTrigger,
  Image,
  Button,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTimes } from "@fortawesome/free-solid-svg-icons";
import useNotifications from "../Hooks/useNotifications";
import userDummy from "../assets/img/Avatar.png";
import "../components/Notification.css";
import useNotificationActions from "../Hooks/useNotificationActions";
import sadEmoji from "../assets/img/sad-emoji.webp";

import LoadingPlaceHolder from "../reusable/Helper/LoadingPlaceHolder";
import CustomToast from "../reusable/CustomToast";

const AllNotifications = () => {
  const {
    notifications,
    isLoading,
    markAsRead,
    deleteNotification,
    toastBody,
    showToast,
    success,
    setShowToast,
  } = useNotifications();
  const { handleNotificationClick, notificationTime } =
    useNotificationActions();

  const allIds = notifications.map((notif) => notif.id);
  const handleAllread = () => {
    markAsRead(allIds);
  };
  const handleAllDelete = () => {
    deleteNotification(allIds);
  };
  return (
    <>
      <h4 className="text-center pt-4 fontweigh-500">All Notifications</h4>{" "}
      <div className="text-end me-4">
        <Button variant="secondary" onClick={handleAllread}>
          Read All
        </Button>{" "}
        <Button variant="danger" onClick={handleAllDelete}>
          Delete All
        </Button>
      </div>
      <div className="container my-4 bg-white px-1">
        <ListGroup className="p-sm-4 ">
          {notifications.length === 0 ? (
            isLoading ? (
              <LoadingPlaceHolder />
            ) : (
              <ListGroup.Item className="text-center bg-light py-4">
                <Image src={sadEmoji} alt="sad-emoji" height={50} width={50} />{" "}
                <p className="fontweigh-500 mt-2">No notifications available</p>
              </ListGroup.Item>
            )
          ) : (
            notifications.map((notification) => (
              <ListGroup.Item
                key={notification.id}
                className={`d-flex justify-content-between align-items-center notification-item mb-3 p-sm-3 cursor-pointer ${
                  notification.read_at ? "read" : "unread"
                }`}
              >
                <div
                  onClick={() => handleNotificationClick(notification)}
                  className={`d-flex align-items-center`}
                >
                  <span className="me-2">
                    <Image
                      alt="notification-logo"
                      src={notification.user_profile_pic || userDummy}
                      width={40}
                      height={40}
                      className="rounded-circle "
                    />{" "}
                  </span>
                  <div>
                    <div className="d-flex">
                      <h5
                        className={`me-1 mb-0 fontweigh-500 ${
                          notification.read_at ? "text-muted" : "green-color"
                        }`}
                      >
                        {notification.name}
                      </h5>{" "}
                      <p className="mb-0">{notification.message}</p>
                    </div>
                    <p className="mb-0 text-muted">
                      {notificationTime(notification.created_at)}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  {!notification.read_at && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id={`tooltip-${notification.id}`}>
                          Mark as Read
                        </Tooltip>
                      }
                    >
                      <div>
                        <FontAwesomeIcon
                          icon={faEye}
                          className="icon-eye me-3"
                          onClick={() => markAsRead(notification.id)}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                    </OverlayTrigger>
                  )}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-delete-${notification.id}`}>
                        Delete
                      </Tooltip>
                    }
                  >
                    <div>
                      <FontAwesomeIcon
                        icon={faTimes}
                        className="icon-cross"
                        onClick={() => deleteNotification(notification.id)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </OverlayTrigger>
                </div>
              </ListGroup.Item>
            ))
          )}
        </ListGroup>
      </div>
      <CustomToast
        show={showToast}
        toastBody={toastBody}
        setShow={setShowToast}
        success={success}
      />
    </>
  );
};

export default AllNotifications;
