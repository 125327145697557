import { useState, useEffect } from "react";

const useIsLgOrSmaller = () => {
  const [isMdOrSmaller, setIsMdOrSmaller] = useState(window.innerWidth <= 768); // Assuming 'md' is 768px

  useEffect(() => {
    const handleResize = () => {
      setIsMdOrSmaller(window.innerWidth <= 992);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMdOrSmaller;
};
export default useIsLgOrSmaller;
