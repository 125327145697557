import React, { useState, useEffect, useMemo } from "react";
import {
  Col,
  Container,
  FormControl,
  FormGroup,
  FormLabel,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import Select from "react-select";
import axios from "../../../axios";
import {
  multiValueHandler,
  valuehandler,
  yesNoOptions,
} from "../../../components/Helpers/HelperFunctions";
// import { useQuery } from "@tanstack/react-query";
// import { useSelector } from "react-redux";
// import debounce from "lodash.debounce";

const ClinicAdvanceInfo = ({ form, setForm, errors, handleChange }) => {
  //   const rankingOptions = useMemo(
  //     () => [
  //       { label: "Top", value: "top" },
  //       { label: "Medium", value: "medium" },
  //       { label: "Low", value: "low" },
  //     ],
  //     []
  //   );

  return (
    <Container fluid className="mt-4">
      <h2 className="text-decoration-underline mb-4 text-center fontweigh-500 fs-4">
        Advance
      </h2>
      <Row>
        {/* {role !== "partner" && (
          <Col lg={3} md={4} sm={6} className="mb-3">
            <FormGroup>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Whether want to go premium</Tooltip>}
              >
                <FormLabel className="fontweigh-500 mb-1">
                  Is Premium <FontAwesomeIcon icon={faInfoCircle} />
                </FormLabel>
              </OverlayTrigger>
              <Select
                placeholder="Is Premium"
                name="is_premium"
                isDisabled={role === "partner"}
                value={valuehandler(yesNoOptions, form.is_premium)}
                onChange={(e) => setForm({ ...form, is_premium: e.value })}
                options={yesNoOptions}
              />
            </FormGroup>
          </Col>
        )}
        {form.is_premium && role !== "partner" && (
          <Col lg={3} md={4} sm={6} className="mb-3">
            <FormGroup>
              <OverlayTrigger
                placement="top"
                overlay={<Tooltip>Choose ranking for the dispensary</Tooltip>}
              >
                <FormLabel className="fontweigh-500 mb-1">
                  Premium Ranking <FontAwesomeIcon icon={faInfoCircle} />
                </FormLabel>
              </OverlayTrigger>
              <Select
                placeholder="Premium Ranking"
                isDisabled={role === "partner"}
                name="premium_ranking"
                value={valuehandler(rankingOptions, form.premium_ranking)}
                onChange={(e) =>
                  setForm({ ...form, premium_ranking: e.value || "" })
                }
                options={rankingOptions}
              />
            </FormGroup>
          </Col>
        )}
        <Col lg={3} md={4} sm={6} className="mb-3">
          <FormGroup>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Is your dispensary recreational</Tooltip>}
            >
              <FormLabel className="fontweigh-500 mb-1">
                Is Recreational <FontAwesomeIcon icon={faInfoCircle} />
              </FormLabel>
            </OverlayTrigger>
            <Select
              placeholder="Is Recreational"
              name="is_recreational"
              value={valuehandler(yesNoOptions, form.is_recreational)}
              onChange={(e) => setForm({ ...form, is_recreational: e.value })}
              options={yesNoOptions}
            />
          </FormGroup>
        </Col>
        <Col lg={3} md={4} sm={6} className="mb-3">
          <FormGroup>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Is your dispensary medical</Tooltip>}
            >
              <FormLabel className="fontweigh-500 mb-1">
                Is Medical <FontAwesomeIcon icon={faInfoCircle} />
              </FormLabel>
            </OverlayTrigger>
            <Select
              placeholder="Is Medical"
              name="is_medical"
              value={valuehandler(yesNoOptions, form.is_medical)}
              onChange={(e) => setForm({ ...form, is_medical: e.value })}
              options={yesNoOptions}
            />
          </FormGroup>
        </Col> */}
        <Col lg={3} md={4} sm={6} className="mb-3">
          <FormGroup>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Want to activate the doctors</Tooltip>}
            >
              <FormLabel className="fontweigh-500 mb-1">
                Is Active <FontAwesomeIcon icon={faInfoCircle} />
              </FormLabel>
            </OverlayTrigger>
            <Select
              placeholder="Is Active"
              name="status"
              value={valuehandler(yesNoOptions, form.status)}
              onChange={(e) => setForm({ ...form, status: e.value })}
              options={yesNoOptions}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6} className="mb-3">
          <FormGroup>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>Add some short information for doctors</Tooltip>
              }
            >
              <FormLabel className="fontweigh-500 mb-1">
                Info <FontAwesomeIcon icon={faInfoCircle} />
              </FormLabel>
            </OverlayTrigger>
            <FormControl
              required
              as="textarea"
              rows="4"
              name="info"
              value={form.info}
              onChange={(e) => handleChange(e, setForm)}
              placeholder="Info"
            />
          </FormGroup>
        </Col>
        <Col md={6} className="mb-3">
          <FormGroup>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip>Add some description for the doctors</Tooltip>}
            >
              <FormLabel className="fontweigh-500 mb-1">
                Description <FontAwesomeIcon icon={faInfoCircle} />
              </FormLabel>
            </OverlayTrigger>
            <FormControl
              required
              as="textarea"
              rows="8"
              name="description"
              value={form.description}
              onChange={(e) => handleChange(e, setForm)}
              placeholder="Description"
            />
          </FormGroup>
        </Col>
      </Row>
    </Container>
  );
};

export default ClinicAdvanceInfo;
