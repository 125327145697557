import React, { useState } from "react";
import { Badge, Button, Dropdown, Image } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Link, useHistory } from "react-router-dom";
import userDummy from "../assets/img/Avatar.png";

import { Routes } from "../routes";
import useNotifications from "../Hooks/useNotifications";
import "./Notification.css";
import useNotificationActions from "../Hooks/useNotificationActions";
import CustomToast from "../reusable/CustomToast";
const Notifications = () => {
  const {
    notifications,
    // isLoading,
    markAsRead,
    deleteNotification,
    toastBody,
    showToast,
    success,
    setShowToast,
  } = useNotifications();

  const { handleNotificationClick, notificationTime } =
    useNotificationActions();

  const [showDropdown, setShowDropdown] = useState(false);

  const unreadCount = notifications.filter(
    (notification) => !notification.read_at
  ).length;
  const allIds = notifications.map((notif) => notif.id);
  const handleAllread = () => {
    markAsRead(allIds);
  };
  const handleAllDelete = () => {
    deleteNotification(allIds);
  };
  return (
    <>
      <Dropdown
        show={showDropdown}
        onToggle={() => setShowDropdown(!showDropdown)}
        className="notification-bell"
      >
        <Dropdown.Toggle
          variant="light"
          id="dropdown-notifications"
          className="bg-white border-0 "
        >
          <FontAwesomeIcon icon={faBell} className="bell-icon link-text" />
          {unreadCount > 0 && (
            <Badge className="notification-badge" bg="warning">
              {unreadCount}
            </Badge>
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu
          align="end"
          className="dropdown-menu-notifications wide-dropdown-menu"
        >
          {notifications.length === 0 ? (
            <Dropdown.ItemText className="text-center">
              No notifications
            </Dropdown.ItemText>
          ) : (
            <>
              <p className="text-muted fw-bold">
                Notification <Badge bg="warning">{unreadCount} new</Badge>{" "}
                <span>
                  <Button
                    variant="secondary"
                    // className="text-white"
                    onClick={handleAllread}
                  >
                    Read all{" "}
                    {/* <FontAwesomeIcon icon={faEye} className="icon-eye me-1" /> */}
                  </Button>{" "}
                  <Button
                    variant="danger"
                    // className="text-danger"
                    onClick={handleAllDelete}
                  >
                    Delete all{" "}
                    {/* <FontAwesomeIcon
                      icon={faTrash}
                      className="icon-eye me-1 text-danger"
                    /> */}
                  </Button>
                </span>
              </p>
              {notifications.map((notification) => (
                <Dropdown.Item
                  key={notification.id}
                  onClick={() => handleNotificationClick(notification)}
                  className={`notification-item d-flex mb-3 ${
                    notification.read_at ? "read" : "unread"
                  }`}
                >
                  <span className="me-2">
                    <Image
                      alt="notification-logo"
                      src={notification.user_profile_pic || userDummy}
                      width={40}
                      height={40}
                      className="rounded-circle "
                    />{" "}
                  </span>
                  <div>
                    <p className="mb-0">
                      <strong className="fs-6">{notification?.name}</strong>{" "}
                      {notification.message}
                    </p>
                    <p className="mb-1 fs-7 text-muted">
                      {notificationTime(notification.created_at)}
                    </p>
                  </div>
                </Dropdown.Item>
              ))}

              <Link
                to={Routes.allNotification.path}
                className="text-white text-center bg-green rounded py-2 d-block"
                onClick={() => setShowDropdown(false)}
              >
                View All
              </Link>
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
      <CustomToast
        show={showToast}
        toastBody={toastBody}
        setShow={setShowToast}
        success={success}
      />
    </>
  );
};

export default Notifications;
