// libraries
import { useQuery } from "@tanstack/react-query";
import { Col, Row, Button } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import axios from "../../axios";

import { loadingSet } from "../../redux/loadingSlice";
import ServerError from "../Login/ServerError";
import LoadingPlaceHolder from "../../reusable/Helper/LoadingPlaceHolder";
import AddEditFAQCat from "../Modals/AddEditFAQCat";
import FAQCatTable from "../../components/Table/FAQCatTable";

const FAQCategories = () => {
  // states
  const [showModal, setShowModal] = useState(false);

  // dispatch
  const dispatch = useDispatch();

  const admin = useSelector((state) => state.login.userData).role_id === 1;

  // Functions
  const fetchFAQCategories = async () => {
    const res = await axios(`/faq/category`);
    return res.data.data;
  };

  const {
    data: faqCats = [],
    isLoading: faqCatLoading,
    error: faqCatError,
    refetch: refetchFaqCat,
  } = useQuery({
    queryKey: ["faq-cat-list"],
    queryFn: fetchFAQCategories,
  });

  // useEffects
  useEffect(() => {
    dispatch(loadingSet(faqCatLoading));
  }, [faqCatLoading, dispatch]);

  if (faqCatError) {
    return (
      <ServerError
        errorMessage={faqCatError?.response?.data?.message || faqCatError}
      />
    );
  }

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
        <div className="d-block mb-4 mb-md-0">
          <h1 className="fontweigh-500 fs-4">FAQ Categories</h1>
        </div>
        <div className="btn-toolbar mb-2 mb-md-0"></div>
      </div>

      <div className="table-settings mb-4">
        {admin && (
          <Row className="justify-content-end align-items-center ">
            <Col xl={2} md={4} lg={3} xs={6}>
              <Row className="justify-content-end me-2">
                <Button
                  onClick={() => setShowModal(true)}
                  className="green-btn fontweigh-500"
                >
                  Add FAQ Category
                </Button>
              </Row>
            </Col>
          </Row>
        )}
      </div>
      {showModal && (
        <AddEditFAQCat
          show={showModal}
          handleClose={() => setShowModal(false)}
          refetchFaqCat={refetchFaqCat}
        />
      )}
      {faqCatLoading ? (
        <LoadingPlaceHolder />
      ) : (
        <FAQCatTable faqCats={faqCats} refetchFaqCat={refetchFaqCat} />
      )}
    </>
  );
};

export default FAQCategories;
