import {
  Button,
  ButtonGroup,
  Card,
  Dropdown,
  Image,
  Row,
  Table,
} from "react-bootstrap";
import { useEffect, useState } from "react";

//axios
import axios from "../../axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEllipsisH,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { loadingSet } from "../../redux/loadingSlice";
import AddEditMutiStepDisp from "../../pages/Modals/MultiStepDispForm/AddEditMultiStepDisp";
import CustomPagination from "../Pagination";
import AddEditMultiStepClinic from "../../pages/Modals/MultiStepClinicForm/AddEditMultiStepClinic";

const ClinicsTable = ({
  clinics,
  refetchClinics,
  totalPages,
  currentPage,
  setCurrentPage,
  setShowToast,
  setToastBody,
  setSuccess,
  tableContainerRef,
}) => {
  //States

  //Add Edit Modal states
  const [showModal, setShowModal] = useState(false);
  const [clinicData, setClinicData] = useState({});

  //loading
  const [loading, setLoading] = useState(false);

  //dispatch
  const dispatch = useDispatch();

  //useEffects
  useEffect(() => {
    dispatch(loadingSet(loading));
  }, [loading, dispatch]);

  //Functions
  const deleteHandler = async (id) => {
    setLoading(true);
    try {
      const res = await axios({
        method: "delete",
        url: `/mmjclinic/delete/${id}`,
      });

      setShowToast(true);
      setToastBody(res.data.message);
      setSuccess(true);
      refetchClinics();
    } catch (err) {
      setShowToast(true);
      setToastBody(err.response.data.message);
      setSuccess(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Card border="light" className="table-wrapper table-responsive shadow-sm">
        <Card.Body className="p-0 justify-content-center">
          <div ref={tableContainerRef} className="table-responsive">
            {clinics?.length > 0 ? (
              <Table hover className="user-table align-items-center min-height">
                <thead>
                  <tr>
                    <th className="border-bottom">ID</th>
                    <th className="border-bottom">Logo</th>
                    <th className="border-bottom">Cover Image</th>
                    <th className="border-bottom">Name</th>

                    <th className="border-bottom">TimeZone</th>
                    <th className="border-bottom">License Number</th>
                    <th className="border-bottom">State</th>
                    <th className="border-bottom">City</th>
                    <th className="border-bottom">Website</th>
                    <th className="border-bottom">E-mail</th>
                    <th className="border-bottom">phone Number</th>

                    <th className="border-bottom">Is Active</th>

                    <th className="border-bottom">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {clinics.map((clinic) => (
                    <tr key={clinic.id}>
                      <td text-label="ID">
                        <span className="fw-normal">{clinic.id}</span>
                      </td>
                      <td text-label="Logo">
                        {clinic.logo ? (
                          <Image
                            src={clinic.logo}
                            alt="Clinic logo"
                            className="fw-normal"
                          />
                        ) : (
                          "N/A"
                        )}
                      </td>

                      <td text-label="Cover Image">
                        <Image
                          src={clinic.cover_image}
                          alt="Clinic cover"
                          className="fw-normal"
                        />
                      </td>
                      <td text-label="Name">
                        <span className="fw-normal">{clinic.name}</span>
                      </td>

                      <td text-label="Timezone">
                        <span className="fw-normal">
                          {clinic?.timezone?.timezone_title}
                        </span>
                      </td>
                      <td text-label="License Number">
                        <span className="fw-normal">
                          {clinic.license_number}
                        </span>
                      </td>
                      <td text-label="State">
                        <span className="fw-normal">{clinic.state}</span>
                      </td>
                      <td text-label="City">
                        <span className="fw-normal">
                          {clinic.city || "N/A"}
                        </span>
                      </td>
                      <td text-label="Website">
                        <span className="fw-normal">
                          {clinic.website || "N/A"}
                        </span>
                      </td>
                      <td text-label="Email">
                        <span className="fw-normal">
                          {clinic.contact_email || "N/A"}
                        </span>
                      </td>
                      <td text-label="Phone Number">
                        <span className="fw-normal">
                          {clinic.phone_number || "N/A"}
                        </span>
                      </td>

                      <td text-label="Status">
                        <span className="fw-normal">
                          {clinic.status ? "Yes" : "No"}
                        </span>
                      </td>

                      <td>
                        <td>
                          <Dropdown as={ButtonGroup} drop="down-centered">
                            <Dropdown.Toggle
                              as={Button}
                              split
                              variant="link"
                              className="text-dark m-0 p-0"
                            >
                              <span className="icon icon-sm">
                                <FontAwesomeIcon
                                  icon={faEllipsisH}
                                  className="icon-dark"
                                />
                              </span>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => {
                                  setShowModal(true);
                                  setClinicData({
                                    id: clinic.id,
                                    name: clinic.name,
                                    timezone_id: clinic.timezone.id,
                                    cover_image: clinic.cover_image,
                                    logo: clinic.logo,
                                    // gallery: clinic.gallery,
                                    license_number: clinic.license_number,
                                    street: clinic.street,
                                    city: clinic.city,
                                    state: clinic.state.state_name,
                                    zip_code: clinic.zip_code,
                                    latitude: clinic.latitude,
                                    longitude: clinic.longitude,
                                    website_link: clinic.website_link,
                                    contact_email: clinic.contact_email,
                                    phone_number: clinic.phone_number || "",
                                    // is_premium: clinic.is_premium,
                                    // premium_ranking: clinic.premium_ranking,
                                    // is_recreational: clinic.is_recreational,
                                    // is_medical: clinic.is_medical,
                                    // products: clinic.products,
                                    status: clinic.status,
                                    info: clinic.info,
                                    description: clinic.description,
                                    social_media: clinic.social_media,
                                    work_hours: clinic.work_hours,
                                  });
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={faEdit}
                                  className="me-2"
                                />{" "}
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                className="text-danger"
                                onClick={() => deleteHandler(clinic.id)}
                              >
                                <FontAwesomeIcon
                                  icon={faTrashAlt}
                                  className="me-2"
                                />{" "}
                                Remove
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <Row className="justify-content-center align-item-center text-dark fontweigh-500 p-4">
                {" "}
                No Data Available.....
              </Row>
            )}
          </div>
        </Card.Body>
        {totalPages > 1 ? (
          <Card.Footer className="px-3 border-0 d-lg-flex align-items-center justify-content-end">
            <CustomPagination
              totalPages={totalPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </Card.Footer>
        ) : (
          ""
        )}
      </Card>
      {showModal && (
        <AddEditMultiStepClinic
          show={showModal}
          handleClose={() => {
            setShowModal(false);
            setClinicData({});
          }}
          clinicData={clinicData}
          type="edit"
          refetchClinics={refetchClinics}
          setShowToast={setShowToast}
          setToastBody={setToastBody}
          setSuccess={setSuccess}
        />
      )}
    </>
  );
};
export default ClinicsTable;
