import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../../axios";
import MultiStepProgressBar from "../MultiStepDispForm/MultiStepProgressBar";
import { loadingSet } from "../../../redux/loadingSlice";
import { handleChange } from "../../../components/Helpers/HelperFunctions";
import ClinicGeneralInfo from "./ClinicGenralInfoStep";
import ClinicAdvanceInfo from "./ClinicAdvanceInfoStep";
import ClinicInfo from "./ClinicInfo";

const AddEditMultiStepClinic = ({
  show,
  handleClose,
  type,
  clinicData,
  refetchClinics,
  setShowToast,
  setSuccess,
  setToastBody,
}) => {
  const dispatch = useDispatch();
  // const { role, plan_id } = useSelector((state) => state.login.userData);
  const loading = useSelector((state) => state.loading.loading);

  const [currentStep, setCurrentStep] = useState(1);
  const [logo, setLogo] = useState("");
  const [cover, setCover] = useState("");
  //   const [galleryImages, setGalleryImages] = useState([]);
  const [socialMedia, setSocialMedia] = useState([
    { social_media_name: "", social_media_link: "" },
  ]);
  const [form, setForm] = useState({
    name: "",
    timezone_id: "",
    cover_image: "",
    logo: "",
    // gallery: [],
    license_number: "",
    street: "",
    city: "",
    state: "",
    zip_code: "",
    latitude: "",
    longitude: "",
    website_link: "",
    contact_email: "",
    phone_number: "",
    status: "",
    info: "",
    description: "",

    work_hours: {
      monday: "",
      tuesday: "",
      wednesday: "",
      thursday: "",
      friday: "",
      saturday: "",
      sunday: "",
    },
  });

  const [errors, setErrors] = useState({});
  useEffect(() => {
    if (type && clinicData) {
      setForm((prevForm) => ({
        ...prevForm,
        ...clinicData,
        // gallery: clinicData?.gallery?.map((g) => g?.gallery_image) || [],
      }));
      setLogo(clinicData?.logo || "");
      setCover(clinicData?.cover_image || "");
      setSocialMedia((prevData) =>
        clinicData?.social_media?.length ? clinicData.social_media : prevData
      );
      //   setGalleryImages(clinicData?.gallery || []);
    }
  }, [clinicData, type]);

  //   useEffect(() => {
  //     setForm((prevForm) => ({
  //       ...prevForm,
  //       gallery: galleryImages.map((g) => g.gallery || g.gallery_image),
  //     }));
  //   }, [galleryImages]);

  const next = useCallback(async () => {
    if (currentStep === 3) {
      dispatch(loadingSet(true));
      try {
        const url = type
          ? `/mmjclinic/update/${clinicData.id}`
          : "/mmjclinic/add";
        const formData = new FormData();

        // Append form fields efficiently
        Object.entries(form).forEach(([key, value]) => {
          if (Array.isArray(value)) {
            value.forEach((item, index) => {
              formData.set(`${key}[${index}]`, item);
            });
          } else if (key === "work_hours") {
            formData.set(key, JSON.stringify(value) || "");
          } else {
            formData.set(key, value || "");
          }
        });

        formData.set("logo", logo || "");
        formData.set("cover_image", cover);

        if (socialMedia[0].social_media_name) {
          socialMedia.forEach((sm, index) => {
            formData.set(`social_media_name[${index}]`, sm.social_media_name);
            formData.set(`social_media_link[${index}]`, sm.social_media_link);
          });
        }

        const res = await axios.post(url, formData);

        setShowToast(true);
        setToastBody(res.data.message);
        setSuccess(true);
        refetchClinics();
        handleClose();
      } catch (err) {
        setShowToast(true);
        setToastBody(err.response?.data?.message || "An error occurred");
        setSuccess(false);
      } finally {
        dispatch(loadingSet(false));
      }
    } else {
      setCurrentStep((prevStep) => prevStep + 1);
    }
  }, [
    currentStep,
    clinicData,
    form,
    logo,
    cover,
    socialMedia,
    dispatch,
    handleClose,
    refetchClinics,
    setShowToast,
    setToastBody,
    setSuccess,
    type,
  ]);

  const handleNextChange = useCallback(() => {
    const newError = {};
    if (currentStep === 1) {
      if (!form.name) newError.name = "Name is required";
      if (!cover) newError.cover_image = "Cover Image is required";

      if (!form.timezone_id) newError.timezone_id = "Timezone is required";
      if (!form.license_number)
        newError.license_number = "License Number is required";
    } else if (currentStep === 2) {
      //   if (!form.product_id.length) newError.product_id = "Product is required";
    } else if (currentStep === 3) {
      if (!form.contact_email)
        newError.contact_email = "Contact Email is required";
      if (
        form.contact_email &&
        (!form.contact_email.includes("@") || !form.contact_email.includes("."))
      )
        newError.contact_email = "Please enter a valid email address";
      if (!form.phone_number)
        newError.phone_number = "Phone Number is required";
      if (!form.street) newError.street = "Street is required";
      if (!form.state) newError.state = "State is required";
      if (!form.zip_code) newError.postal_code = "Zip Code is required";
    }

    setErrors(newError);
    if (Object.keys(newError).length === 0) {
      next();
    } else {
      setShowToast(true);
      setToastBody("Please fill all the mandatory fields");
      setSuccess(false);
    }
  }, [currentStep, form, cover, next, setShowToast, setSuccess, setToastBody]);

  return (
    <Modal show={show} onHide={handleClose} fullscreen>
      <ModalHeader closeButton>
        <Modal.Title className="fontweigh-500">
          {type ? "Edit Doctor" : "Add New Doctor"}
        </Modal.Title>
      </ModalHeader>
      <ModalBody>
        <Row className="p-4">
          <MultiStepProgressBar currentStep={currentStep} />
        </Row>
        {currentStep === 1 && (
          <ClinicGeneralInfo
            form={form}
            setForm={setForm}
            errors={errors}
            handleChange={handleChange}
            logo={logo}
            setLogo={setLogo}
            cover={cover}
            setCover={setCover}
            // galleryImages={galleryImages}
            // setGalleryImages={setGalleryImages}
          />
        )}
        {currentStep === 2 && (
          <ClinicAdvanceInfo
            form={form}
            setForm={setForm}
            errors={errors}
            handleChange={handleChange}
          />
        )}
        {currentStep === 3 && (
          <ClinicInfo
            form={form}
            setForm={setForm}
            socialMedia={socialMedia}
            setSocialMedia={setSocialMedia}
            errors={errors}
            handleChange={handleChange}
            setShowToast={setShowToast}
            setToastBody={setToastBody}
            setSuccess={setSuccess}
          />
        )}
      </ModalBody>
      <ModalFooter className="mx-5">
        <Button
          variant="outline-danger"
          onClick={handleClose}
          disabled={loading}
        >
          Close
        </Button>
        {currentStep > 1 && (
          <Button
            variant="secondary"
            onClick={() => setCurrentStep((prevStep) => prevStep - 1)}
            disabled={loading}
          >
            Previous
          </Button>
        )}
        <Button
          className="green-btn fontweigh-500"
          onClick={handleNextChange}
          disabled={loading}
        >
          {currentStep === 3 ? (loading ? "Saving..." : "Save") : "Next"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddEditMultiStepClinic;
